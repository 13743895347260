<template>
    <title>Terms of Use | Elfrique – Complete Event Management System</title>
    <elfrique-header/>
    
    <!--Resource Breadcrumb-->
    <section class="resource-breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-md-7 head-banner-text">
                    <h1>Terms of Use</h1>
                    <div class="line-rule"></div>
                    <p>Elfrique manages this website to provide you with information about the services we offer, and information about Elfrique platform.</p>
                </div>
            </div>
        </div>
    </section>
    <!--Resource Breadcrumb Ends-->

    <!--Resource Note-->
    <section class="resource-note">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-11">
                    <p>Welcome to www.elfrique.com website (the "Site") of Elfrique Solutions Limited.</p>
                    <p>This Site has been created to provide complete management solutions to our esteemed customers. Please read our terms and conditions carefully. The use of our Site is subject to these terms and conditions.</p>
                    <p>We may change our terms from time to time and such changes will take effect as and when published on this Site. If you do not accept our terms of use, please do not make use of our Site.</p>
                    <h1 class="alert-text">IMPORTANT NOTICE</h1>
                    <p class="alert-text">If you are not yet 18 you must obtain your parents’ or legal guardians’ consent and permission to be bound by the terms of our Site prior to your use of our site.</p>
                    <p class="alert-text">We act solely as an ecosystem and independent platform to help service provides leverage on the use of our platform to engage their audience better. We do not own some of the services offered on the website nor control the quality of services they provide. We only bridge the gap between customers and service providers.</p>
                    <h1>PURCHASING ON ELFRIQUE WEBSITE:</h1>
                    <p>To purchase any item, the automated purchase process must be completed, and payment information submitted to us in the prescribed manner. Our purchase system will provide User with an opportunity to review the entire transaction, to correct any mistakes and to withdraw from the transaction before finally submitting a request.</p>
                    <h1>USERNAME AND PASSWORDS:</h1>
                    <p>Users are responsible for keeping their usernames and passwords for the Site secure. We are not responsible for losses incurred through negligence or misuse by third parties.</p>
                    <h1>PAYMENTS:</h1>
                    <p>Users will be required to provide payment details (i.e. credit card or debit card details) when submitting a purchase request. Payment may be made via Visa, MasterCard, USSD etc. Our website does not store payment card information. By submitting a payment request, permission is granted to debit the designated account with the equivalent amount. An email receipt would be sent immediately payment is confirmed.</p>
                    <p>If a payment is made using the Offline payment method (Bank Transfers, Cash Deposit option), Users are to quote the reference number generated on the website while initiating the transfer or cash deposit to Elfrique provided bank account. Payment would be activated when a payment slip carrying the reference number is received.</p>
                    <h1>CREDIT CARD SECURITY:</h1>
                    <p>No credit card details are stored on Elfrique’s servers. Users may go to our partner payment gateways to view their policies. Payments for international transaction are processed using our services.</p>
                    <h1>COUNTRY AND TRANSACTION CURRENCY:</h1>
                    <p>Elfrique has a base currency in Naira (Nigerian Currency) and the Elfrique website converts this to the local currency of Users. We use a dynamic currency converter. Users in any part of the world will get to see their Local currency when processing payments on Elfrique.</p>
                    <h1>PURCHASED ITEM DELIVERY:</h1>
                    <p>For most of the services that we offer, value is given immediately. For services that will require special delivery, detailed guild on how to receive items purchased would be sent via email.</p>
                    <h1>PAYMENT TO SERVICE PROVIDERS:</h1>
                    <p>Unless otherwise agreed in writing, Elfrique will make pay-out within 1 to 7 days for all online and USSD payments processed. For direct Airtime deductions from end-users, pay-out is mailed within 30 to 60 days when service is ended. However, service provider may request upfront / advance payment in writing, and it may be honoured.</p>
                    <h1>SERVICE FEE / COMMISSION:</h1>
                    <p>Please refer to the rate on the website for current charges.</p>
                    <p>In the case where a service provider is directly collecting funds, the Service provider is liable to pay any fees and commission due to Elfrique as agreed.</p>
                    <h1>LOST, STOLEN AND DESTROYED PAYMENT RECEIPT:</h1>
                    <p>Only a person with an electronic receipt containing the relevant person’s name, unique reference number, will be attended to.</p>
                    <h1>CANCELLATION AND REFUNDS:</h1>
                    <p>By the User:</p>
                    <p>For payments that were processed without providing value to the user, the user should notify Elfrique by email at accounts@elfrique.com and quote the reference number in the contact mail. On receipt of the refund request, the transaction would be investigated and responded to. Typical replies are obtained within 1 to 48 hours.</p>
                    <p>By the Service Provider:</p>
                    <p>Service Providers may postpone or cancel their events. In such cases, all users who have paid or indicated interest will be refunded or communicated to by the service provider. Service providers acknowledge that they are bound to comply with all applicable laws. Service providers must make refund in accordance with all applicable laws, and indemnify Elfrique against any and all losses, including reputational losses, which Elfrique may incur as a result of Service Providers not complying with their legal obligations regarding refund to Users.</p>
                    <h1 class="alert-text">DISCLAIMER:</h1>
                    <p class="alert-text">Elfrique Services are provided subject to applicable laws and consumer protection legislations, but are otherwise provided without any warranty, and not subject to any condition (express or implied), except as specifically stated in the Site.</p>
                    <p class="alert-text">Elfrique will endeavor to make payments to Service providers as soon as reasonably possible, however, Elfrique makes no representations or warranties regarding the amount of time needed to complete processing of such payments because such processing is dependent upon some third parties and many factors beyond our control.</p>
                    <p class="alert-text">While Elfrique undertakes to use reasonable measures to maintain the operation of Elfrique Services, we cannot guarantee continuous, uninterrupted, or secure access to the service and operation of the Site and mobile systems. Elfrique shall not be liable for any delay or failure in the running of Elfrique Services, or the working of the Site, where such failure is due to factors beyond Elfrique’s control.</p>
                    <p class="alert-text">Elfrique will not be held liable for any indirect, special, or consequential losses of any kind whatsoever arising from, or in connection with any purchases (whether in contract, statute or delict).</p>
                    <p class="alert-text">Users acknowledge that access to the Site may occasionally be restricted to allow for repairs, maintenance or the introduction of new functionalities or services. We will attempt to restore the Site as soon as reasonably possible.</p>
                    <h1>PRIVACY POLICY:</h1>
                    <p>Elfrique undertakes to take all due care with any information provided by users when accessing the Site. However, it cannot guarantee the security of any information which a user may provide. Information transmitted to Elfrique is transmitted at the user's own risk.</p>
                    <h1>DISPUTE RESOLUTION AND CHARGEBACKS:</h1>
                    <p>All users agree to work actively with the other parties to transactions to resolve any disputes that may arise. In the event that a transaction is suspected of fraud, Elfrique reserves the right to request any proof deemed necessary to determine the validity of the transaction. If any charge-back claim occurs (as the result of a dispute or for any reason whatsoever), Users agree to provide the Organizer with all the necessary documentation in order to resolve the claim. Organizers must acknowledge that Elfrique does not control the outcome of the charge-back decision reached by the issuing bank; accept the decision of the issuing bank as final and legally binding; and recognize that payment may not be received if the issuing bank rules against the Service Provider in relation to the payment received.</p>
                    <h1>INDEMNITY BY SERVICE PROVIDER:</h1>
                    <p>The Service Provider hereby indemnifies Elfrique and holds it harmless for any claims instituted against Elfrique by any user in terms of where the loss suffered by such user was suffered as a result of negligence or misconduct on the part of the Service Provider, including the failure by the Service Provider to comply with its legal obligations. Where losses arise partly due to the negligence of Elfrique and partly due to negligence or misconduct of the Service Provider, the Service Provider will indemnify Elfrique pro rata for such claim instituted by such claimant, having regard to relevant operating laws.</p>
                    <p>The Service Provider indemnifies Elfrique against any loss or consequential loss suffered or incurred by Elfrique arising out of, or in connection with, a charge-back or refund claim made against Elfrique, where pay-out has already been made to the relevant Service Provider by Elfrique. The Service Provider hereby undertakes that it will reimburse Elfrique for any financial loss suffered as a result of payment made to the Service Provider for sales made on Elfrique website.</p>
                    <p>Where Elfrique suffers any loss, including but not limited to reputational loss, as a result of the conduct of the Service Provider, including but not limited to the Service Providers failure to comply with its legal obligations, including but not limited to, under relevant consumer protection laws, the Service Provider indemnifies Elfrique fully for any such loss incurred by Elfrique.</p>
                    <h1>ACCOUNT PASSWORDS AND REGISTRATION:</h1>
                    <p>Users agree that the information provided to Elfrique upon registration and at all other times will be true, accurate, current, and complete. Furthermore, users will ensure that all information is kept accurate and up-to-date at all times. If a user has reason to believe that its account is no longer secure (e.g., in the event of a loss, theft or unauthorized disclosure or use of an email address, password, or any credit, debit or charge card number, if applicable), then users agree to immediately notify Elfrique.</p>
                    <h1>OTHER AGREEMENT:</h1>
                    <p>These terms constitute the entire agreement between users and Elfrique in respect of the use of the Site and the Elfrique Services offered:</p>
                    <ol>
                        <li>You must be a human to use our site.</li>
                        <li>You must be 18 years or older to have an account on our platform.</li>
                        <li>Your login details may only be used by one person – a single login shared by multiple people is not permitted.</li>
                        <li>DO NOT use Elfrique for any illegal or unauthorized purpose. You must not, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</li>
                        <li>Technical support is only provided via email, phone call and live chat.</li>
                        <li>You must not modify, adapt, or hack the Service or modify another website to falsely imply that it is associated with Elfrique.</li>
                        <li>You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Service, use the Service, or access the Service without the express permission of Elfrique Solutions Limited.</li>
                        <li>You must not upload, post, host, mislead or transmit unsolicited email, SMS, or “spam” messages from our website.</li>
                        <li>You must not transmit any worms or viruses or any code of a destructive nature to or through the Site.</li>
                    </ol>
                    <h1>COMPANY INFORMATION:</h1>
                    <p>This Site is run by Elfrique Solutions Limited, based in Nigeria (West Africa). We are a private company incorporated in accordance with the laws of the Federal Republic of Nigeria with registration number RC 1331532. We are VAT registered and compliant.</p>
                    <p><span>Elfrique operates from</span> 77 Ojuelegba Road Surulere, Lagos State, Nigeria.</p>
                    <p><span>Telephone:</span> <a href="tel:+2349071130455">+234 907 113 0455,</a> <a href="tel:+2349013209138">+234 901 320 9138</a></p>
                    <p><span>Email:</span> <a href="mailto:info@elfrique.com">info@elfrique.com</a></p>
                    <p><span>Website:</span> <a href="www.elfrique.com">www.elfrique.com</a></p>
                    <p><strong>Thank you!</strong></p>
                </div>
            </div>
        </div>
    </section>
    <!--Resource Note Ends-->

    <elfrique-footer/>
</template>

<script>
    import Header from './elfrique-header.vue'
    import Footer from './elfrique-footer.vue'
    export default {
      name: "Elfrique",
      components:{
      'elfrique-header':Header,
      'elfrique-footer':Footer,
      },
      mounted(){
        window.scrollTo(0,0)
      }
    }
</script>