<template>
    <title>Event Vendor Management System | Elfrique – Complete Event Management System</title>
    <elfrique-header/>

    <!--Service Header-->
    <section class="service-header detailsVendorHeader">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-11 text-center">
                    <h1>Event Decorators</h1>
                    <p>Find suitable vendors for your jobs in Nigeria</p>
                </div>
            </div>
        </div>
    </section>
    <!--Service Header Ends-->

    <!--Advert Here-->
    <div class="container">
        <div class="row">
            <div class="col-lg-12 horizontal-advert mt-3">
                <a href="#" target="_blank">
                    <img src="@/assets/images/advert-banner3.jpg" ondragstart="return false;" alt="advert">
                </a>
            </div>
        </div>
    </div>

    <!--View Vendor Section-->
    <section class="viewVendor">
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <div class="profileCard">
                        <a href="/vendor-profile">
                            <div class="imgArea">
                                <img src="../assets/images/vendor-profile.jpg">
                            </div>
                        </a>
                        <div class="detailsArea">
                            <p><i class="bi bi-person"></i> : <span>Chukka Uzo</span></p>
                            <p><i class="bi bi-briefcase"></i> : <span>20 Jobs Completed</span></p>
                            <p><i class="bi bi-star"></i> : <span>5 <i class="fa fa-star"></i></span></p>
                        </div>
                        <a href="/vendor-profile">View Vendor <i class="bi bi-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="profileCard">
                        <a href="#">
                            <div class="imgArea">
                                <img src="../assets/images/vendor-profile.jpg">
                            </div>
                        </a>
                        <div class="detailsArea">
                            <p><i class="bi bi-person"></i> : <span>Rebecca Gold</span></p>
                            <p><i class="bi bi-briefcase"></i> : <span>15 Jobs Completed</span></p>
                            <p><i class="bi bi-star"></i> : <span>4 <i class="fa fa-star"></i></span></p>
                        </div>
                        <a href="#">View Vendor <i class="bi bi-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="profileCard">
                        <a href="#">
                            <div class="imgArea">
                                <img src="../assets/images/vendor-profile.jpg">
                            </div>
                        </a>
                        <div class="detailsArea">
                            <p><i class="bi bi-person"></i> : <span>Oluwa Janet</span></p>
                            <p><i class="bi bi-briefcase"></i> : <span>14 Jobs Completed</span></p>
                            <p><i class="bi bi-star"></i> : <span>5 <i class="fa fa-star"></i></span></p>
                        </div>
                        <a href="#">View Vendor <i class="bi bi-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="profileCard">
                        <a href="#">
                            <div class="imgArea">
                                <img src="../assets/images/vendor-profile.jpg">
                            </div>
                        </a>
                        <div class="detailsArea">
                            <p><i class="bi bi-person"></i> : <span>julius Benson</span></p>
                            <p><i class="bi bi-briefcase"></i> : <span>2 Jobs Completed</span></p>
                            <p><i class="bi bi-star"></i> : <span>4 <i class="fa fa-star"></i></span></p>
                        </div>
                        <a href="#">View Vendor <i class="bi bi-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="profileCard">
                        <a href="#">
                            <div class="imgArea">
                                <img src="../assets/images/vendor-profile.jpg">
                            </div>
                        </a>
                        <div class="detailsArea">
                            <p><i class="bi bi-person"></i> : <span>Amaka King</span></p>
                            <p><i class="bi bi-briefcase"></i> : <span>7 Jobs Completed</span></p>
                            <p><i class="bi bi-star"></i> : <span>4 <i class="fa fa-star"></i></span></p>
                        </div>
                        <a href="#">View Vendor <i class="bi bi-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="profileCard">
                        <a href="#">
                            <div class="imgArea">
                                <img src="../assets/images/vendor-profile.jpg">
                            </div>
                        </a>
                        <div class="detailsArea">
                            <p><i class="bi bi-person"></i> : <span>Sandra Uzo</span></p>
                            <p><i class="bi bi-briefcase"></i> : <span>2 Jobs Completed</span></p>
                            <p><i class="bi bi-star"></i> : <span>3 <i class="fa fa-star"></i></span></p>
                        </div>
                        <a href="#">View Vendor <i class="bi bi-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="profileCard">
                        <a href="#">
                            <div class="imgArea">
                                <img src="../assets/images/vendor-profile.jpg">
                            </div>
                        </a>
                        <div class="detailsArea">
                            <p><i class="bi bi-person"></i> : <span>Madson Square</span></p>
                            <p><i class="bi bi-briefcase"></i> : <span>25 Jobs Completed</span></p>
                            <p><i class="bi bi-star"></i> : <span>3 <i class="fa fa-star"></i></span></p>
                        </div>
                        <a href="#">View Vendor <i class="bi bi-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="profileCard">
                        <a href="#">
                            <div class="imgArea">
                                <img src="../assets/images/vendor-profile.jpg">
                            </div>
                        </a>
                        <div class="detailsArea">
                            <p><i class="bi bi-person"></i> : <span>Nath Olu</span></p>
                            <p><i class="bi bi-briefcase"></i> : <span>1 Jobs Completed</span></p>
                            <p><i class="bi bi-star"></i> : <span>5 <i class="fa fa-star"></i></span></p>
                        </div>
                        <a href="#">View Vendor <i class="bi bi-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-12 py-3text-center">
                    <nav>
                        <ul class="pagination justify-content-center">
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </section>

    <!--Advert Here-->
    <div class="container">
        <div class="row">
            <div class="col-lg-12 horizontal-advert mt-3">
                <a href="#" target="_blank">
                    <img src="@/assets/images/advert-banner3.jpg" ondragstart="return false;" alt="advert">
                </a>
            </div>
        </div>
    </div>
    
    <elfrique-footer/>
</template>

<script>
    import Header from './elfrique-header.vue'
    import Newsletter from './elfrique-newsletter.vue'
    import Footer from './elfrique-footer.vue'
    export default {
      name: "Elfrique",
      components:{
      'elfrique-header':Header,
      'elfrique-newsletter':Newsletter,
      'elfrique-footer':Footer,
      },
      mounted(){
        window.scrollTo(0,0)
      }
    }
</script>