<template>
    <!---E-visa Footer--->
    <section class="evisaFooter">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <p>
                    &copy; <span id="autodate"></span> 
                    Elfrique Solutions - All Rights Reserved.
                    </p>
                </div>
            </div>
        </div>
    </section>
    <!---E-visa Footer Ends--->
</template>

<script>
    export default {
        mounted(){
         window.scrollTo(0,0)
        }
    }
</script>