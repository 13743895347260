import axios from "axios";
import authHeader from "./auth-header2";
import authHeader2 from "./auth-header";

const API_URL = "https://elfrique-proj.herokuapp.com/api/v1/";

class EventForm {
  createForm(eventForm) {
    return axios.post(API_URL + "createForm", eventForm, {
      headers: authHeader(),
    });
  }

  buildForm(questionForm, formId) {
    return axios.post(API_URL + "buildForm/" + formId, questionForm, {
      headers: authHeader(),
    });
  }

  /* createAward(awardForm) {
    return axios.post(API_URL + "createAward", awardForm, {
      headers: authHeader(),
    });
  } */

  getForms() {
    return axios.get(API_URL + "getAllForm", { headers: authHeader() });
  }

  getContests() {
    return axios.get(API_URL + "getallVote", { headers: authHeader() });
  }

  createTickets(ticketForm, eventId) {
    return axios.post(API_URL + "createTickets/" + eventId, ticketForm, {
      headers: authHeader2(),
    });
  }

  getCategories(contestId) {
    return axios.get(API_URL + "getallCategories/" + contestId, {
      headers: authHeader(),
    });
  }

  addsponsor(sponsorForm, contestId) {
    return axios.post(API_URL + "addSponsor/" + contestId, sponsorForm, {
      headers: authHeader(),
    });
  }

  addInfo(infoForm, contestId) {
    return axios.post(API_URL + "addInfo/" + contestId, infoForm, {
      headers: authHeader2(),
    });
  }

  addCategory(categoryForm, contestId) {
    return axios.post(API_URL + "createCategories/" + contestId, categoryForm, {
      headers: authHeader2(),
    });
  }

  addNominees(nomineeForm, contestName, categoryId) {
    return axios.post(
      API_URL + "createNominees/" + contestName + "/" + categoryId,
      nomineeForm,
      {
        headers: authHeader(),
      }
    );
  }

  allForms() {
    return axios.get(API_URL + "allForms", { headers: authHeader() });
  }

  getSingleForm(formId) {
    return axios.get(API_URL + "getForm/" + formId, { headers: authHeader() });
  }

  allFormForAdmin() {
    return axios.get(API_URL + "getForms", { headers: authHeader() });
  }
}

export default new EventForm();
