<template>
    <title>Create Event | Elfrique</title>
    <dash-header/>

    <!--------Main Content--------->
    <main id="main" class="main">
        <div class="pagetitle">
            <h1>Create Event</h1>
            <nav>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/organiser/dashboard" class="routers"><a>Home</a></router-link></li>
                    <li class="breadcrumb-item active">Registration</li>
                    <li class="breadcrumb-item active">Create Event</li>
                </ol>
            </nav>
        </div><!-- End Page Title -->
        <div  v-if="error" class=" alert-danger alert  alert-dismissible fade show" role="alert">
                           {{error}} 
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div  v-if="message" class= 'alert-success alert  alert-dismissible fade show' role="alert">
                        {{message}} 
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>

        <div class="container start-voting-div create-event-div">
            <div class="row justify-content-center">
                 <form name="form" @submit.prevent="createEvents">
                    <div class="row justify-content-center">
                        <div class="col-lg-11 event-details-header">Details</div>
                        <!--Details DIV-->
                        <div class="col-lg-11 start-voting-inner-div">
                            <div class="row">
                                <!--Event Title-->
                                <div class="col-lg-12 mt-2">
                                    <label for="event title">Event Title</label>
                                    <input v-model="eventContent.title" class="input" type="text" placeholder="Enter event title" required>
                                </div>
                                <!--Country-->
                                <div class="col-lg-6 mt-4">
                                    <label for="country">Country</label>
                                    <select v-model="eventContent.country" class="input" required name="country">
                                        <option value="select country" hidden>Select Country</option>
                                        <option value="Afganistan">Afghanistan</option>
                                        <option value="Albania">Albania</option>
                                        <option value="Algeria">Algeria</option>
                                        <option value="American Samoa">American Samoa</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Angola">Angola</option>
                                        <option value="Anguilla">Anguilla</option>
                                        <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                        <option value="Argentina">Argentina</option>
                                        <option value="Armenia">Armenia</option>
                                        <option value="Aruba">Aruba</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Austria">Austria</option>
                                        <option value="Azerbaijan">Azerbaijan</option>
                                        <option value="Bahamas">Bahamas</option>
                                        <option value="Bahrain">Bahrain</option>
                                        <option value="Bangladesh">Bangladesh</option>
                                        <option value="Barbados">Barbados</option>
                                        <option value="Belarus">Belarus</option>
                                        <option value="Belgium">Belgium</option>
                                        <option value="Belize">Belize</option>
                                        <option value="Benin">Benin</option>
                                        <option value="Bermuda">Bermuda</option>
                                        <option value="Bhutan">Bhutan</option>
                                        <option value="Bolivia">Bolivia</option>
                                        <option value="Bonaire">Bonaire</option>
                                        <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                        <option value="Botswana">Botswana</option>
                                        <option value="Brazil">Brazil</option>
                                        <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                        <option value="Brunei">Brunei</option>
                                        <option value="Bulgaria">Bulgaria</option>
                                        <option value="Burkina Faso">Burkina Faso</option>
                                        <option value="Burundi">Burundi</option>
                                        <option value="Cambodia">Cambodia</option>
                                        <option value="Cameroon">Cameroon</option>
                                        <option value="Canada">Canada</option>
                                        <option value="Canary Islands">Canary Islands</option>
                                        <option value="Cape Verde">Cape Verde</option>
                                        <option value="Cayman Islands">Cayman Islands</option>
                                        <option value="Central African Republic">Central African Republic</option>
                                        <option value="Chad">Chad</option>
                                        <option value="Channel Islands">Channel Islands</option>
                                        <option value="Chile">Chile</option>
                                        <option value="China">China</option>
                                        <option value="Christmas Island">Christmas Island</option>
                                        <option value="Cocos Island">Cocos Island</option>
                                        <option value="Colombia">Colombia</option>
                                        <option value="Comoros">Comoros</option>
                                        <option value="Congo">Congo</option>
                                        <option value="Cook Islands">Cook Islands</option>
                                        <option value="Costa Rica">Costa Rica</option>
                                        <option value="Cote DIvoire">Cote DIvoire</option>
                                        <option value="Croatia">Croatia</option>
                                        <option value="Cuba">Cuba</option>
                                        <option value="Curaco">Curacao</option>
                                        <option value="Cyprus">Cyprus</option>
                                        <option value="Czech Republic">Czech Republic</option>
                                        <option value="Denmark">Denmark</option>
                                        <option value="Djibouti">Djibouti</option>
                                        <option value="Dominica">Dominica</option>
                                        <option value="Dominican Republic">Dominican Republic</option>
                                        <option value="East Timor">East Timor</option>
                                        <option value="Ecuador">Ecuador</option>
                                        <option value="Egypt">Egypt</option>
                                        <option value="El Salvador">El Salvador</option>
                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                        <option value="Eritrea">Eritrea</option>
                                        <option value="Estonia">Estonia</option>
                                        <option value="Ethiopia">Ethiopia</option>
                                        <option value="Falkland Islands">Falkland Islands</option>
                                        <option value="Faroe Islands">Faroe Islands</option>
                                        <option value="Fiji">Fiji</option>
                                        <option value="Finland">Finland</option>
                                        <option value="France">France</option>
                                        <option value="French Guiana">French Guiana</option>
                                        <option value="French Polynesia">French Polynesia</option>
                                        <option value="French Southern Ter">French Southern Ter</option>
                                        <option value="Gabon">Gabon</option>
                                        <option value="Gambia">Gambia</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Germany">Germany</option>
                                        <option value="Ghana">Ghana</option>
                                        <option value="Gibraltar">Gibraltar</option>
                                        <option value="Great Britain">Great Britain</option>
                                        <option value="Greece">Greece</option>
                                        <option value="Greenland">Greenland</option>
                                        <option value="Grenada">Grenada</option>
                                        <option value="Guadeloupe">Guadeloupe</option>
                                        <option value="Guam">Guam</option>
                                        <option value="Guatemala">Guatemala</option>
                                        <option value="Guinea">Guinea</option>
                                        <option value="Guyana">Guyana</option>
                                        <option value="Haiti">Haiti</option>
                                        <option value="Hawaii">Hawaii</option>
                                        <option value="Honduras">Honduras</option>
                                        <option value="Hong Kong">Hong Kong</option>
                                        <option value="Hungary">Hungary</option>
                                        <option value="Iceland">Iceland</option>
                                        <option value="Indonesia">Indonesia</option>
                                        <option value="India">India</option>
                                        <option value="Iran">Iran</option>
                                        <option value="Iraq">Iraq</option>
                                        <option value="Ireland">Ireland</option>
                                        <option value="Isle of Man">Isle of Man</option>
                                        <option value="Israel">Israel</option>
                                        <option value="Italy">Italy</option>
                                        <option value="Jamaica">Jamaica</option>
                                        <option value="Japan">Japan</option>
                                        <option value="Jordan">Jordan</option>
                                        <option value="Kazakhstan">Kazakhstan</option>
                                        <option value="Kenya">Kenya</option>
                                        <option value="Kiribati">Kiribati</option>
                                        <option value="Korea North">Korea North</option>
                                        <option value="Korea Sout">Korea South</option>
                                        <option value="Kuwait">Kuwait</option>
                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                        <option value="Laos">Laos</option>
                                        <option value="Latvia">Latvia</option>
                                        <option value="Lebanon">Lebanon</option>
                                        <option value="Lesotho">Lesotho</option>
                                        <option value="Liberia">Liberia</option>
                                        <option value="Libya">Libya</option>
                                        <option value="Liechtenstein">Liechtenstein</option>
                                        <option value="Lithuania">Lithuania</option>
                                        <option value="Luxembourg">Luxembourg</option>
                                        <option value="Macau">Macau</option>
                                        <option value="Macedonia">Macedonia</option>
                                        <option value="Madagascar">Madagascar</option>
                                        <option value="Malaysia">Malaysia</option>
                                        <option value="Malawi">Malawi</option>
                                        <option value="Maldives">Maldives</option>
                                        <option value="Mali">Mali</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Marshall Islands">Marshall Islands</option>
                                        <option value="Martinique">Martinique</option>
                                        <option value="Mauritania">Mauritania</option>
                                        <option value="Mauritius">Mauritius</option>
                                        <option value="Mayotte">Mayotte</option>
                                        <option value="Mexico">Mexico</option>
                                        <option value="Midway Islands">Midway Islands</option>
                                        <option value="Moldova">Moldova</option>
                                        <option value="Monaco">Monaco</option>
                                        <option value="Mongolia">Mongolia</option>
                                        <option value="Montserrat">Montserrat</option>
                                        <option value="Morocco">Morocco</option>
                                        <option value="Mozambique">Mozambique</option>
                                        <option value="Myanmar">Myanmar</option>
                                        <option value="Nambia">Nambia</option>
                                        <option value="Nauru">Nauru</option>
                                        <option value="Nepal">Nepal</option>
                                        <option value="Netherland Antilles">Netherland Antilles</option>
                                        <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                        <option value="Nevis">Nevis</option>
                                        <option value="New Caledonia">New Caledonia</option>
                                        <option value="New Zealand">New Zealand</option>
                                        <option value="Nicaragua">Nicaragua</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Nigeria">Nigeria</option>
                                        <option value="Niue">Niue</option>
                                        <option value="Norfolk Island">Norfolk Island</option>
                                        <option value="Norway">Norway</option>
                                        <option value="Oman">Oman</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Palau Island">Palau Island</option>
                                        <option value="Palestine">Palestine</option>
                                        <option value="Panama">Panama</option>
                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                        <option value="Paraguay">Paraguay</option>
                                        <option value="Peru">Peru</option>
                                        <option value="Phillipines">Philippines</option>
                                        <option value="Pitcairn Island">Pitcairn Island</option>
                                        <option value="Poland">Poland</option>
                                        <option value="Portugal">Portugal</option>
                                        <option value="Puerto Rico">Puerto Rico</option>
                                        <option value="Qatar">Qatar</option>
                                        <option value="Republic of Montenegro">Republic of Montenegro</option>
                                        <option value="Republic of Serbia">Republic of Serbia</option>
                                        <option value="Reunion">Reunion</option>
                                        <option value="Romania">Romania</option>
                                        <option value="Russia">Russia</option>
                                        <option value="Rwanda">Rwanda</option>
                                        <option value="St Barthelemy">St Barthelemy</option>
                                        <option value="St Eustatius">St Eustatius</option>
                                        <option value="St Helena">St Helena</option>
                                        <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                        <option value="St Lucia">St Lucia</option>
                                        <option value="St Maarten">St Maarten</option>
                                        <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                        <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                        <option value="Saipan">Saipan</option>
                                        <option value="Samoa">Samoa</option>
                                        <option value="Samoa American">Samoa American</option>
                                        <option value="San Marino">San Marino</option>
                                        <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                        <option value="Senegal">Senegal</option>
                                        <option value="Seychelles">Seychelles</option>
                                        <option value="Sierra Leone">Sierra Leone</option>
                                        <option value="Singapore">Singapore</option>
                                        <option value="Slovakia">Slovakia</option>
                                        <option value="Slovenia">Slovenia</option>
                                        <option value="Solomon Islands">Solomon Islands</option>
                                        <option value="Somalia">Somalia</option>
                                        <option value="South Africa">South Africa</option>
                                        <option value="Spain">Spain</option>
                                        <option value="Sri Lanka">Sri Lanka</option>
                                        <option value="Sudan">Sudan</option>
                                        <option value="Suriname">Suriname</option>
                                        <option value="Swaziland">Swaziland</option>
                                        <option value="Sweden">Sweden</option>
                                        <option value="Switzerland">Switzerland</option>
                                        <option value="Syria">Syria</option>
                                        <option value="Tahiti">Tahiti</option>
                                        <option value="Taiwan">Taiwan</option>
                                        <option value="Tajikistan">Tajikistan</option>
                                        <option value="Tanzania">Tanzania</option>
                                        <option value="Thailand">Thailand</option>
                                        <option value="Togo">Togo</option>
                                        <option value="Tokelau">Tokelau</option>
                                        <option value="Tonga">Tonga</option>
                                        <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                        <option value="Tunisia">Tunisia</option>
                                        <option value="Turkey">Turkey</option>
                                        <option value="Turkmenistan">Turkmenistan</option>
                                        <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                        <option value="Tuvalu">Tuvalu</option>
                                        <option value="Uganda">Uganda</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="Ukraine">Ukraine</option>
                                        <option value="United Arab Erimates">United Arab Emirates</option>
                                        <option value="United States of America">United States of America</option>
                                        <option value="Uraguay">Uruguay</option>
                                        <option value="Uzbekistan">Uzbekistan</option>
                                        <option value="Vanuatu">Vanuatu</option>
                                        <option value="Vatican City State">Vatican City State</option>
                                        <option value="Venezuela">Venezuela</option>
                                        <option value="Vietnam">Vietnam</option>
                                        <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                        <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                        <option value="Wake Island">Wake Island</option>
                                        <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                        <option value="Yemen">Yemen</option>
                                        <option value="Zaire">Zaire</option>
                                        <option value="Zambia">Zambia</option>
                                        <option value="Zimbabwe">Zimbabwe</option>
                                    </select>
                                </div>
                                <!--State-->
                                <div class="col-lg-6 mt-4">
                                    <label for="state">State</label>
                                    <input  v-model="eventContent.state" class="input" type="text" placeholder="Enter state" required>
                                </div>
                                <!--City-->
                                <div class="col-lg-6 mt-4">
                                    <label for="city">City</label>
                                    <input  v-model="eventContent.city" class="input" type="text" placeholder="Enter city" required>
                                </div>
                                <!--Location-->
                                <div class="col-lg-6 mt-4">
                                    <label for="location">Location/Venue</label>
                                    <input  v-model="eventContent.venue" class="input" type="text" placeholder="Enter event location" required>
                                </div>
                                <!--Start Date-->
                                <div class="col-lg-6 mt-4">
                                    <label for="start date">Start Date</label>
                                    <input  v-model="eventContent.startdate" class="input" type="date" required>
                                </div>
                                <!--End Date-->
                                <div class="col-lg-6 mt-4">
                                    <label for="end date">End Date</label>
                                    <input  v-model="eventContent.enddate" class="input" type="date" required>
                                </div>
                                <!--Event Image-->
                                <div class="col-lg-12 mt-4">
                                    <label for="event image">Event Image</label>
                                    <input class="input" type="file" ref="file" accept=".jpg, .jpeg, .png, .jfif"  v-on:change="handleFileUpload()" required>
                                    <small class="text-danger font-weight-bold">(only .jpg, .jpeg, .png, .jfif or .webp format) </small>
                                </div>
                                <!--Event Description-->
                                <div class="col-lg-12 mt-4">
                                    <label for="event description">Event Description</label>
                                    <textarea  v-model="eventContent.description" class="input" cols="30" rows="4" placeholder="Enter details here..." ></textarea>
                                </div>
                                <!--Organiser Name-->
                                <div class="col-lg-12 mt-4">
                                    <label for="organiser name">Organiser Name</label>
                                    <input  v-model="eventContent.organizer" class="input" type="text" placeholder="Enter name" required>
                                </div>
                                <!--Payment Gateway-->
                                <div class="col-lg-12 mt-4">
                                    <label for="payment gateway">Select Payment Gateway</label>
                                    <select  v-model="eventContent.paymentgateway" name="gateway" id="gateway" required>
                                        <option value="paystack">Paystack</option>
                                        <option value="flutterwave">Flutterwave</option>
                                        <option value="payu">Payu</option>
                                        <option value="interswitch">Interswitch</option>
                                    </select>
                                </div>
                                <!--Timezone-->
                                <div class="col-lg-12 mt-4">
                                    <label for="select timezone">Select Your Timezone</label>
                                    <select  v-model="eventContent.timezone" name="timezone" id="timezone" required>
                                        <option value="Africa/Abidjan">Africa/Abidjan</option>
                                        <option value="Africa/Accra">Africa/Accra</option>
                                        <option value="Africa/Addis_Ababa">Africa/Addis_Ababa</option>
                                        <option value="Africa/Algiers">Africa/Algiers</option>
                                        <option value="Africa/Asmara">Africa/Asmara</option>
                                        <option value="Africa/Bamako">Africa/Bamako</option>
                                        <option value="Africa/Bangui">Africa/Bangui</option>
                                        <option value="Africa/Banjul">Africa/Banjul</option>
                                        <option value="Africa/Bissau">Africa/Bissau</option>
                                        <option value="Africa/Blantyre">Africa/Blantyre</option>
                                        <option value="Africa/Brazzaville">Africa/Brazzaville</option>
                                        <option value="Africa/Bujumbura">Africa/Bujumbura</option>
                                        <option value="Africa/Cairo">Africa/Cairo</option>
                                        <option value="Africa/Casablanca">Africa/Casablanca</option>
                                        <option value="Africa/Ceuta">Africa/Ceuta</option>
                                        <option value="Africa/Conakry">Africa/Conakry</option>
                                        <option value="Africa/Dakar">Africa/Dakar</option>
                                        <option value="Africa/Dar_es_Salaam">Africa/Dar_es_Salaam</option>
                                        <option value="Africa/Djibouti">Africa/Djibouti</option>
                                        <option value="Africa/Douala">Africa/Douala</option>
                                        <option value="Africa/El_Aaiun">Africa/El_Aaiun</option>
                                        <option value="Africa/Freetown">Africa/Freetown</option>
                                        <option value="Africa/Gaborone">Africa/Gaborone</option>
                                        <option value="Africa/Harare">Africa/Harare</option>
                                        <option value="Africa/Johannesburg">Africa/Johannesburg</option>
                                        <option value="Africa/Juba">Africa/Juba</option>
                                        <option value="Africa/Kampala">Africa/Kampala</option>
                                        <option value="Africa/Khartoum">Africa/Khartoum</option>
                                        <option value="Africa/Kigali">Africa/Kigali</option>
                                        <option value="Africa/Kinshasa">Africa/Kinshasa</option>
                                        <option value="Africa/Lagos" selected>Africa/Lagos</option>
                                        <option value="Africa/Libreville">Africa/Libreville</option>
                                        <option value="Africa/Lome">Africa/Lome</option>
                                        <option value="Africa/Luanda">Africa/Luanda</option>
                                        <option value="Africa/Lubumbashi">Africa/Lubumbashi</option>
                                        <option value="Africa/Lusaka">Africa/Lusaka</option>
                                        <option value="Africa/Malabo">Africa/Malabo</option>
                                        <option value="Africa/Maputo">Africa/Maputo</option>
                                        <option value="Africa/Maseru">Africa/Maseru</option>
                                        <option value="Africa/Mbabane">Africa/Mbabane</option>
                                        <option value="Africa/Mogadishu">Africa/Mogadishu</option>
                                        <option value="Africa/Monrovia">Africa/Monrovia</option>
                                        <option value="Africa/Nairobi">Africa/Nairobi</option>
                                        <option value="Africa/Ndjamena">Africa/Ndjamena</option>
                                        <option value="Africa/Niamey">Africa/Niamey</option>
                                        <option value="Africa/Nouakchott">Africa/Nouakchott</option>
                                        <option value="Africa/Ouagadougou">Africa/Ouagadougou</option>
                                        <option value="Africa/Porto-Novo">Africa/Porto-Novo</option>
                                        <option value="Africa/Sao_Tome">Africa/Sao_Tome</option>
                                        <option value="Africa/Tripoli">Africa/Tripoli</option>
                                        <option value="Africa/Tunis">Africa/Tunis</option>
                                        <option value="Africa/Windhoek">Africa/Windhoek</option>
                                        <option value="America/Adak">America/Adak</option>
                                        <option value="America/Anchorage">America/Anchorage</option>
                                        <option value="America/Anguilla">America/Anguilla</option>
                                        <option value="America/Antigua">America/Antigua</option>
                                        <option value="America/Araguaina">America/Araguaina</option>
                                        <option value="America/Argentina/Buenos_Aires">America/Argentina/Buenos_Aires</option>
                                        <option value="America/Argentina/Catamarca">America/Argentina/Catamarca</option>
                                        <option value="America/Argentina/Cordoba">America/Argentina/Cordoba</option>
                                        <option value="America/Argentina/Jujuy">America/Argentina/Jujuy</option>
                                        <option value="America/Argentina/La_Rioja">America/Argentina/La_Rioja</option>
                                        <option value="America/Argentina/Mendoza">America/Argentina/Mendoza</option>
                                        <option value="America/Argentina/Rio_Gallegos">America/Argentina/Rio_Gallegos</option>
                                        <option value="America/Argentina/Salta">America/Argentina/Salta</option>
                                        <option value="America/Argentina/San_Juan">America/Argentina/San_Juan</option>
                                        <option value="America/Argentina/San_Luis">America/Argentina/San_Luis</option>
                                        <option value="America/Argentina/Tucuman">America/Argentina/Tucuman</option>
                                        <option value="America/Argentina/Ushuaia">America/Argentina/Ushuaia</option>
                                        <option value="America/Aruba">America/Aruba</option>
                                        <option value="America/Asuncion">America/Asuncion</option>
                                        <option value="America/Atikokan">America/Atikokan</option>
                                        <option value="America/Bahia">America/Bahia</option>
                                        <option value="America/Bahia_Banderas">America/Bahia_Banderas</option>
                                        <option value="America/Barbados">America/Barbados</option>
                                        <option value="America/Belem">America/Belem</option>
                                        <option value="America/Belize">America/Belize</option>
                                        <option value="America/Blanc-Sablon">America/Blanc-Sablon</option>
                                        <option value="America/Boa_Vista">America/Boa_Vista</option>
                                        <option value="America/Bogota">America/Bogota</option>
                                        <option value="America/Boise">America/Boise</option>
                                        <option value="America/Cambridge_Bay">America/Cambridge_Bay</option>
                                        <option value="America/Campo_Grande">America/Campo_Grande</option>
                                        <option value="America/Cancun">America/Cancun</option>
                                        <option value="America/Caracas">America/Caracas</option>
                                        <option value="America/Cayenne">America/Cayenne</option>
                                        <option value="America/Cayman">America/Cayman</option>
                                        <option value="America/Chicago">America/Chicago</option>
                                        <option value="America/Chihuahua">America/Chihuahua</option>
                                        <option value="America/Costa_Rica">America/Costa_Rica</option>
                                        <option value="America/Creston">America/Creston</option>
                                        <option value="America/Cuiaba">America/Cuiaba</option>
                                        <option value="America/Curacao">America/Curacao</option>
                                        <option value="America/Danmarkshavn">America/Danmarkshavn</option>
                                        <option value="America/Dawson">America/Dawson</option>
                                        <option value="America/Dawson_Creek">America/Dawson_Creek</option>
                                        <option value="America/Denver">America/Denver</option>
                                        <option value="America/Detroit">America/Detroit</option>
                                        <option value="America/Dominica">America/Dominica</option>
                                        <option value="America/Edmonton">America/Edmonton</option>
                                        <option value="America/Eirunepe">America/Eirunepe</option>
                                        <option value="America/El_Salvador">America/El_Salvador</option>
                                        <option value="America/Fort_Nelson">America/Fort_Nelson</option>
                                        <option value="America/Fortaleza">America/Fortaleza</option>
                                        <option value="America/Glace_Bay">America/Glace_Bay</option>
                                        <option value="America/Goose_Bay">America/Goose_Bay</option>
                                        <option value="America/Grand_Turk">America/Grand_Turk</option>
                                        <option value="America/Grenada">America/Grenada</option>
                                        <option value="America/Guadeloupe">America/Guadeloupe</option>
                                        <option value="America/Guatemala">America/Guatemala</option>
                                        <option value="America/Guayaquil">America/Guayaquil</option>
                                        <option value="America/Guyana">America/Guyana</option>
                                        <option value="America/Halifax">America/Halifax</option>
                                        <option value="America/Havana">America/Havana</option>
                                        <option value="America/Hermosillo">America/Hermosillo</option>
                                        <option value="America/Indiana/Indianapolis">America/Indiana/Indianapolis</option>
                                        <option value="America/Indiana/Knox">America/Indiana/Knox</option>
                                        <option value="America/Indiana/Marengo">America/Indiana/Marengo</option>
                                        <option value="America/Indiana/Petersburg">America/Indiana/Petersburg</option>
                                        <option value="America/Indiana/Tell_City">America/Indiana/Tell_City</option>
                                        <option value="America/Indiana/Vevay">America/Indiana/Vevay</option>
                                        <option value="America/Indiana/Vincennes">America/Indiana/Vincennes</option>
                                        <option value="America/Indiana/Winamac">America/Indiana/Winamac</option>
                                        <option value="America/Inuvik">America/Inuvik</option>
                                        <option value="America/Iqaluit">America/Iqaluit</option>
                                        <option value="America/Jamaica">America/Jamaica</option>
                                        <option value="America/Juneau">America/Juneau</option>
                                        <option value="America/Kentucky/Louisville">America/Kentucky/Louisville</option>
                                        <option value="America/Kentucky/Monticello">America/Kentucky/Monticello</option>
                                        <option value="America/Kralendijk">America/Kralendijk</option>
                                        <option value="America/La_Paz">America/La_Paz</option>
                                        <option value="America/Lima">America/Lima</option>
                                        <option value="America/Los_Angeles">America/Los_Angeles</option>
                                        <option value="America/Lower_Princes">America/Lower_Princes</option>
                                        <option value="America/Maceio">America/Maceio</option>
                                        <option value="America/Managua">America/Managua</option>
                                        <option value="America/Manaus">America/Manaus</option>
                                        <option value="America/Marigot">America/Marigot</option>
                                        <option value="America/Martinique">America/Martinique</option>
                                        <option value="America/Matamoros">America/Matamoros</option>
                                        <option value="America/Mazatlan">America/Mazatlan</option>
                                        <option value="America/Menominee">America/Menominee</option>
                                        <option value="America/Merida">America/Merida</option>
                                        <option value="America/Metlakatla">America/Metlakatla</option>
                                        <option value="America/Mexico_City">America/Mexico_City</option>
                                        <option value="America/Miquelon">America/Miquelon</option>
                                        <option value="America/Moncton">America/Moncton</option>
                                        <option value="America/Monterrey">America/Monterrey</option>
                                        <option value="America/Montevideo">America/Montevideo</option>
                                        <option value="America/Montserrat">America/Montserrat</option>
                                        <option value="America/Nassau">America/Nassau</option>
                                        <option value="America/New_York">America/New_York</option>
                                        <option value="America/Nipigon">America/Nipigon</option>
                                        <option value="America/Nome">America/Nome</option>
                                        <option value="America/Noronha">America/Noronha</option>
                                        <option value="America/North_Dakota/Beulah">America/North_Dakota/Beulah</option>
                                        <option value="America/North_Dakota/Center">America/North_Dakota/Center</option>
                                        <option value="America/North_Dakota/New_Salem">America/North_Dakota/New_Salem</option>
                                        <option value="America/Nuuk">America/Nuuk</option>
                                        <option value="America/Ojinaga">America/Ojinaga</option>
                                        <option value="America/Panama">America/Panama</option>
                                        <option value="America/Pangnirtung">America/Pangnirtung</option>
                                        <option value="America/Paramaribo">America/Paramaribo</option>
                                        <option value="America/Phoenix">America/Phoenix</option>
                                        <option value="America/Port-au-Prince">America/Port-au-Prince</option>
                                        <option value="America/Port_of_Spain">America/Port_of_Spain</option>
                                        <option value="America/Porto_Velho">America/Porto_Velho</option>
                                        <option value="America/Puerto_Rico">America/Puerto_Rico</option>
                                        <option value="America/Punta_Arenas">America/Punta_Arenas</option>
                                        <option value="America/Rainy_River">America/Rainy_River</option>
                                        <option value="America/Rankin_Inlet">America/Rankin_Inlet</option>
                                        <option value="America/Recife">America/Recife</option>
                                        <option value="America/Regina">America/Regina</option>
                                        <option value="America/Resolute">America/Resolute</option>
                                        <option value="America/Rio_Branco">America/Rio_Branco</option>
                                        <option value="America/Santarem">America/Santarem</option>
                                        <option value="America/Santiago">America/Santiago</option>
                                        <option value="America/Santo_Domingo">America/Santo_Domingo</option>
                                        <option value="America/Sao_Paulo">America/Sao_Paulo</option>
                                        <option value="America/Scoresbysund">America/Scoresbysund</option>
                                        <option value="America/Sitka">America/Sitka</option>
                                        <option value="America/St_Barthelemy">America/St_Barthelemy</option>
                                        <option value="America/St_Johns">America/St_Johns</option>
                                        <option value="America/St_Kitts">America/St_Kitts</option>
                                        <option value="America/St_Lucia">America/St_Lucia</option>
                                        <option value="America/St_Thomas">America/St_Thomas</option>
                                        <option value="America/St_Vincent">America/St_Vincent</option>
                                        <option value="America/Swift_Current">America/Swift_Current</option>
                                        <option value="America/Tegucigalpa">America/Tegucigalpa</option>
                                        <option value="America/Thule">America/Thule</option>
                                        <option value="America/Thunder_Bay">America/Thunder_Bay</option>
                                        <option value="America/Tijuana">America/Tijuana</option>
                                        <option value="America/Toronto">America/Toronto</option>
                                        <option value="America/Tortola">America/Tortola</option>
                                        <option value="America/Vancouver">America/Vancouver</option>
                                        <option value="America/Whitehorse">America/Whitehorse</option>
                                        <option value="America/Winnipeg">America/Winnipeg</option>
                                        <option value="America/Yakutat">America/Yakutat</option>
                                        <option value="America/Yellowknife">America/Yellowknife</option>
                                        <option value="Antarctica/Casey">Antarctica/Casey</option>
                                        <option value="Antarctica/Davis">Antarctica/Davis</option>
                                        <option value="Antarctica/DumontDUrville">Antarctica/DumontDUrville</option>
                                        <option value="Antarctica/Macquarie">Antarctica/Macquarie</option>
                                        <option value="Antarctica/Mawson">Antarctica/Mawson</option>
                                        <option value="Antarctica/McMurdo">Antarctica/McMurdo</option>
                                        <option value="Antarctica/Palmer">Antarctica/Palmer</option>
                                        <option value="Antarctica/Rothera">Antarctica/Rothera</option>
                                        <option value="Antarctica/Syowa">Antarctica/Syowa</option>
                                        <option value="Antarctica/Troll">Antarctica/Troll</option>
                                        <option value="Antarctica/Vostok">Antarctica/Vostok</option>
                                        <option value="Arctic/Longyearbyen">Arctic/Longyearbyen</option>
                                        <option value="Asia/Aden">Asia/Aden</option>
                                        <option value="Asia/Almaty">Asia/Almaty</option>
                                        <option value="Asia/Amman">Asia/Amman</option>
                                        <option value="Asia/Anadyr">Asia/Anadyr</option>
                                        <option value="Asia/Aqtau">Asia/Aqtau</option>
                                        <option value="Asia/Aqtobe">Asia/Aqtobe</option>
                                        <option value="Asia/Ashgabat">Asia/Ashgabat</option>
                                        <option value="Asia/Atyrau">Asia/Atyrau</option>
                                        <option value="Asia/Baghdad">Asia/Baghdad</option>
                                        <option value="Asia/Bahrain">Asia/Bahrain</option>
                                        <option value="Asia/Baku">Asia/Baku</option>
                                        <option value="Asia/Bangkok">Asia/Bangkok</option>
                                        <option value="Asia/Barnaul">Asia/Barnaul</option>
                                        <option value="Asia/Beirut">Asia/Beirut</option>
                                        <option value="Asia/Bishkek">Asia/Bishkek</option>
                                        <option value="Asia/Brunei">Asia/Brunei</option>
                                        <option value="Asia/Chita">Asia/Chita</option>
                                        <option value="Asia/Choibalsan">Asia/Choibalsan</option>
                                        <option value="Asia/Colombo">Asia/Colombo</option>
                                        <option value="Asia/Damascus">Asia/Damascus</option>
                                        <option value="Asia/Dhaka">Asia/Dhaka</option>
                                        <option value="Asia/Dili">Asia/Dili</option>
                                        <option value="Asia/Dubai">Asia/Dubai</option>
                                        <option value="Asia/Dushanbe">Asia/Dushanbe</option>
                                        <option value="Asia/Famagusta">Asia/Famagusta</option>
                                        <option value="Asia/Gaza">Asia/Gaza</option>
                                        <option value="Asia/Hebron">Asia/Hebron</option>
                                        <option value="Asia/Ho_Chi_Minh">Asia/Ho_Chi_Minh</option>
                                        <option value="Asia/Hong_Kong">Asia/Hong_Kong</option>
                                        <option value="Asia/Hovd">Asia/Hovd</option>
                                        <option value="Asia/Irkutsk">Asia/Irkutsk</option>
                                        <option value="Asia/Jakarta">Asia/Jakarta</option>
                                        <option value="Asia/Jayapura">Asia/Jayapura</option>
                                        <option value="Asia/Jerusalem">Asia/Jerusalem</option>
                                        <option value="Asia/Kabul">Asia/Kabul</option>
                                        <option value="Asia/Kamchatka">Asia/Kamchatka</option>
                                        <option value="Asia/Karachi">Asia/Karachi</option>
                                        <option value="Asia/Kathmandu">Asia/Kathmandu</option>
                                        <option value="Asia/Khandyga">Asia/Khandyga</option>
                                        <option value="Asia/Kolkata">Asia/Kolkata</option>
                                        <option value="Asia/Krasnoyarsk">Asia/Krasnoyarsk</option>
                                        <option value="Asia/Kuala_Lumpur">Asia/Kuala_Lumpur</option>
                                        <option value="Asia/Kuching">Asia/Kuching</option>
                                        <option value="Asia/Kuwait">Asia/Kuwait</option>
                                        <option value="Asia/Macau">Asia/Macau</option>
                                        <option value="Asia/Magadan">Asia/Magadan</option>
                                        <option value="Asia/Makassar">Asia/Makassar</option>
                                        <option value="Asia/Manila">Asia/Manila</option>
                                        <option value="Asia/Muscat">Asia/Muscat</option>
                                        <option value="Asia/Nicosia">Asia/Nicosia</option>
                                        <option value="Asia/Novokuznetsk">Asia/Novokuznetsk</option>
                                        <option value="Asia/Novosibirsk">Asia/Novosibirsk</option>
                                        <option value="Asia/Omsk">Asia/Omsk</option>
                                        <option value="Asia/Oral">Asia/Oral</option>
                                        <option value="Asia/Phnom_Penh">Asia/Phnom_Penh</option>
                                        <option value="Asia/Pontianak">Asia/Pontianak</option>
                                        <option value="Asia/Pyongyang">Asia/Pyongyang</option>
                                        <option value="Asia/Qatar">Asia/Qatar</option>
                                        <option value="Asia/Qostanay">Asia/Qostanay</option>
                                        <option value="Asia/Qyzylorda">Asia/Qyzylorda</option>
                                        <option value="Asia/Riyadh">Asia/Riyadh</option>
                                        <option value="Asia/Sakhalin">Asia/Sakhalin</option>
                                        <option value="Asia/Samarkand">Asia/Samarkand</option>
                                        <option value="Asia/Seoul">Asia/Seoul</option>
                                        <option value="Asia/Shanghai">Asia/Shanghai</option>
                                        <option value="Asia/Singapore">Asia/Singapore</option>
                                        <option value="Asia/Srednekolymsk">Asia/Srednekolymsk</option>
                                        <option value="Asia/Taipei">Asia/Taipei</option>
                                        <option value="Asia/Tashkent">Asia/Tashkent</option>
                                        <option value="Asia/Tbilisi">Asia/Tbilisi</option>
                                        <option value="Asia/Tehran">Asia/Tehran</option>
                                        <option value="Asia/Thimphu">Asia/Thimphu</option>
                                        <option value="Asia/Tokyo">Asia/Tokyo</option>
                                        <option value="Asia/Tomsk">Asia/Tomsk</option>
                                        <option value="Asia/Ulaanbaatar">Asia/Ulaanbaatar</option>
                                        <option value="Asia/Urumqi">Asia/Urumqi</option>
                                        <option value="Asia/Ust-Nera">Asia/Ust-Nera</option>
                                        <option value="Asia/Vientiane">Asia/Vientiane</option>
                                        <option value="Asia/Vladivostok">Asia/Vladivostok</option>
                                        <option value="Asia/Yakutsk">Asia/Yakutsk</option>
                                        <option value="Asia/Yangon">Asia/Yangon</option>
                                        <option value="Asia/Yekaterinburg">Asia/Yekaterinburg</option>
                                        <option value="Asia/Yerevan">Asia/Yerevan</option>
                                        <option value="Atlantic/Azores">Atlantic/Azores</option>
                                        <option value="Atlantic/Bermuda">Atlantic/Bermuda</option>
                                        <option value="Atlantic/Canary">Atlantic/Canary</option>
                                        <option value="Atlantic/Cape_Verde">Atlantic/Cape_Verde</option>
                                        <option value="Atlantic/Faroe">Atlantic/Faroe</option>
                                        <option value="Atlantic/Madeira">Atlantic/Madeira</option>
                                        <option value="Atlantic/Reykjavik">Atlantic/Reykjavik</option>
                                        <option value="Atlantic/South_Georgia">Atlantic/South_Georgia</option>
                                        <option value="Atlantic/St_Helena">Atlantic/St_Helena</option>
                                        <option value="Atlantic/Stanley">Atlantic/Stanley</option>
                                        <option value="Australia/Adelaide">Australia/Adelaide</option>
                                        <option value="Australia/Brisbane">Australia/Brisbane</option>
                                        <option value="Australia/Broken_Hill">Australia/Broken_Hill</option>
                                        <option value="Australia/Darwin">Australia/Darwin</option>
                                        <option value="Australia/Eucla">Australia/Eucla</option>
                                        <option value="Australia/Hobart">Australia/Hobart</option>
                                        <option value="Australia/Lindeman">Australia/Lindeman</option>
                                        <option value="Australia/Lord_Howe">Australia/Lord_Howe</option>
                                        <option value="Australia/Melbourne">Australia/Melbourne</option>
                                        <option value="Australia/Perth">Australia/Perth</option>
                                        <option value="Australia/Sydney">Australia/Sydney</option>
                                        <option value="Europe/Amsterdam">Europe/Amsterdam</option>
                                        <option value="Europe/Andorra">Europe/Andorra</option>
                                        <option value="Europe/Astrakhan">Europe/Astrakhan</option>
                                        <option value="Europe/Athens">Europe/Athens</option>
                                        <option value="Europe/Belgrade">Europe/Belgrade</option>
                                        <option value="Europe/Berlin">Europe/Berlin</option>
                                        <option value="Europe/Bratislava">Europe/Bratislava</option>
                                        <option value="Europe/Brussels">Europe/Brussels</option>
                                        <option value="Europe/Bucharest">Europe/Bucharest</option>
                                        <option value="Europe/Budapest">Europe/Budapest</option>
                                        <option value="Europe/Busingen">Europe/Busingen</option>
                                        <option value="Europe/Chisinau">Europe/Chisinau</option>
                                        <option value="Europe/Copenhagen">Europe/Copenhagen</option>
                                        <option value="Europe/Dublin">Europe/Dublin</option>
                                        <option value="Europe/Gibraltar">Europe/Gibraltar</option>
                                        <option value="Europe/Guernsey">Europe/Guernsey</option>
                                        <option value="Europe/Helsinki">Europe/Helsinki</option>
                                        <option value="Europe/Isle_of_Man">Europe/Isle_of_Man</option>
                                        <option value="Europe/Istanbul">Europe/Istanbul</option>
                                        <option value="Europe/Jersey">Europe/Jersey</option>
                                        <option value="Europe/Kaliningrad">Europe/Kaliningrad</option>
                                        <option value="Europe/Kiev">Europe/Kiev</option>
                                        <option value="Europe/Kirov">Europe/Kirov</option>
                                        <option value="Europe/Lisbon">Europe/Lisbon</option>
                                        <option value="Europe/Ljubljana">Europe/Ljubljana</option>
                                        <option value="Europe/London">Europe/London</option>
                                        <option value="Europe/Luxembourg">Europe/Luxembourg</option>
                                        <option value="Europe/Madrid">Europe/Madrid</option>
                                        <option value="Europe/Malta">Europe/Malta</option>
                                        <option value="Europe/Mariehamn">Europe/Mariehamn</option>
                                        <option value="Europe/Minsk">Europe/Minsk</option>
                                        <option value="Europe/Monaco">Europe/Monaco</option>
                                        <option value="Europe/Moscow">Europe/Moscow</option>
                                        <option value="Europe/Oslo">Europe/Oslo</option>
                                        <option value="Europe/Paris">Europe/Paris</option>
                                        <option value="Europe/Podgorica">Europe/Podgorica</option>
                                        <option value="Europe/Prague">Europe/Prague</option>
                                        <option value="Europe/Riga">Europe/Riga</option>
                                        <option value="Europe/Rome">Europe/Rome</option>
                                        <option value="Europe/Samara">Europe/Samara</option>
                                        <option value="Europe/San_Marino">Europe/San_Marino</option>
                                        <option value="Europe/Sarajevo">Europe/Sarajevo</option>
                                        <option value="Europe/Saratov">Europe/Saratov</option>
                                        <option value="Europe/Simferopol">Europe/Simferopol</option>
                                        <option value="Europe/Skopje">Europe/Skopje</option>
                                        <option value="Europe/Sofia">Europe/Sofia</option>
                                        <option value="Europe/Stockholm">Europe/Stockholm</option>
                                        <option value="Europe/Tallinn">Europe/Tallinn</option>
                                        <option value="Europe/Tirane">Europe/Tirane</option>
                                        <option value="Europe/Ulyanovsk">Europe/Ulyanovsk</option>
                                        <option value="Europe/Uzhgorod">Europe/Uzhgorod</option>
                                        <option value="Europe/Vaduz">Europe/Vaduz</option>
                                        <option value="Europe/Vatican">Europe/Vatican</option>
                                        <option value="Europe/Vienna">Europe/Vienna</option>
                                        <option value="Europe/Vilnius">Europe/Vilnius</option>
                                        <option value="Europe/Volgograd">Europe/Volgograd</option>
                                        <option value="Europe/Warsaw">Europe/Warsaw</option>
                                        <option value="Europe/Zagreb">Europe/Zagreb</option>
                                        <option value="Europe/Zaporozhye">Europe/Zaporozhye</option>
                                        <option value="Europe/Zurich">Europe/Zurich</option>
                                        <option value="Indian/Antananarivo">Indian/Antananarivo</option>
                                        <option value="Indian/Chagos">Indian/Chagos</option>
                                        <option value="Indian/Christmas">Indian/Christmas</option>
                                        <option value="Indian/Cocos">Indian/Cocos</option>
                                        <option value="Indian/Comoro">Indian/Comoro</option>
                                        <option value="Indian/Kerguelen">Indian/Kerguelen</option>
                                        <option value="Indian/Mahe">Indian/Mahe</option>
                                        <option value="Indian/Maldives">Indian/Maldives</option>
                                        <option value="Indian/Mauritius">Indian/Mauritius</option>
                                        <option value="Indian/Mayotte">Indian/Mayotte</option>
                                        <option value="Indian/Reunion">Indian/Reunion</option>
                                        <option value="Pacific/Apia">Pacific/Apia</option>
                                        <option value="Pacific/Auckland">Pacific/Auckland</option>
                                        <option value="Pacific/Bougainville">Pacific/Bougainville</option>
                                        <option value="Pacific/Chatham">Pacific/Chatham</option>
                                        <option value="Pacific/Chuuk">Pacific/Chuuk</option>
                                        <option value="Pacific/Easter">Pacific/Easter</option>
                                        <option value="Pacific/Efate">Pacific/Efate</option>
                                        <option value="Pacific/Enderbury">Pacific/Enderbury</option>
                                        <option value="Pacific/Fakaofo">Pacific/Fakaofo</option>
                                        <option value="Pacific/Fiji">Pacific/Fiji</option>
                                        <option value="Pacific/Funafuti">Pacific/Funafuti</option>
                                        <option value="Pacific/Galapagos">Pacific/Galapagos</option>
                                        <option value="Pacific/Gambier">Pacific/Gambier</option>
                                        <option value="Pacific/Guadalcanal">Pacific/Guadalcanal</option>
                                        <option value="Pacific/Guam">Pacific/Guam</option>
                                        <option value="Pacific/Honolulu">Pacific/Honolulu</option>
                                        <option value="Pacific/Kiritimati">Pacific/Kiritimati</option>
                                        <option value="Pacific/Kosrae">Pacific/Kosrae</option>
                                        <option value="Pacific/Kwajalein">Pacific/Kwajalein</option>
                                        <option value="Pacific/Majuro">Pacific/Majuro</option>
                                        <option value="Pacific/Marquesas">Pacific/Marquesas</option>
                                        <option value="Pacific/Midway">Pacific/Midway</option>
                                        <option value="Pacific/Nauru">Pacific/Nauru</option>
                                        <option value="Pacific/Niue">Pacific/Niue</option>
                                        <option value="Pacific/Norfolk">Pacific/Norfolk</option>
                                        <option value="Pacific/Noumea">Pacific/Noumea</option>
                                        <option value="Pacific/Pago_Pago">Pacific/Pago_Pago</option>
                                        <option value="Pacific/Palau">Pacific/Palau</option>
                                        <option value="Pacific/Pitcairn">Pacific/Pitcairn</option>
                                        <option value="Pacific/Pohnpei">Pacific/Pohnpei</option>
                                        <option value="Pacific/Port_Moresby">Pacific/Port_Moresby</option>
                                        <option value="Pacific/Rarotonga">Pacific/Rarotonga</option>
                                        <option value="Pacific/Saipan">Pacific/Saipan</option>
                                        <option value="Pacific/Tahiti">Pacific/Tahiti</option>
                                        <option value="Pacific/Tarawa">Pacific/Tarawa</option>
                                        <option value="Pacific/Tongatapu">Pacific/Tongatapu</option>
                                        <option value="Pacific/Wake">Pacific/Wake</option>
                                        <option value="Pacific/Wallis">Pacific/Wallis</option>
                                        <option value="UTC">UTC</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-11 event-details-header mt-4">Category</div>
                        <!--Category DIV-->
                        <div class="col-lg-11 start-voting-inner-div">
                            <div class="row">
                                <!--Event Title-->
                                <div class="col-lg-12 mt-2">
                                    <label for="number of contestants">Event Category</label>
                                    <select  v-model="eventContent.category" id="category" name="category" required>
                                        <option value="select category" hidden>Select a Category</option>
                                        <option value="1">Conference</option>
                                        <option value="2">Music & Dance</option>
                                        <option value="3">Fashion</option>
                                        <option value="4">Dinners</option>
                                        <option value="5">Concerts</option>
                                        <option value="6"> Food & Drinks</option>
                                        <option value="7">Parties</option>
                                        <option value="8">Sports</option>
                                        <option value="9">Arts</option>
                                        <option value="10">Seminars</option>
                                        <option value="11">Conferences</option>
                                        <option value="12">Networking</option>
                                        <option value="13">A.G.M's</option>
                                        <option value="14">Exhibitions</option>
                                        <option value="15">Trainings</option>
                                        <option value="16">Classes</option>
                                        <option value="17">Government</option>
                                        <option value="18">NGO's</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-11 mt-4">
                            <button type="submit" class="btn btn-success" :disabled="loading" >Create Event<span v-show="loading" class="spinner-border spinner-border-sm"></span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </main>

    <dash-footer/>
</template>
<style scoped src="@/assets/css/dashStyle.css"></style>
<script>
    import Header from './dash-header.vue'
    import Footer from './dash-footer.vue'
    import EventService from '../../service/event.service'
    export default {
      name: "Elfrique",
      components:{
      'dash-header': Header,
      'dash-footer': Footer,
      },
      data(){
          return{
              eventContent:{
                    title:'',
                    description:'',
                    startdate:'',
                    enddate:'',
                    timezone:'Africa/Lagos',
                    venue:'',
                    country:'',
                    state:'',
                    city:'',
                    paymentgateway:'',
                    category:'',
                    organizer:'',

                    
                },
                file: '',
                error: '',
                loading: false,
                message: '',

            }
        },

    computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    
  },

  created() {

    if (!this.loggedIn) {
      this.$router.push('/login');
        }
    },

    methods:{
        createEvents(){
            this.loading = true;

            let formData = new FormData();
            formData.append('image', this.file);                                                                                                                                                                                                                                           
            formData.append('title', this.eventContent.title);
            formData.append('startdate', this.eventContent.startdate);
            formData.append('enddate', this.eventContent.enddate);
            formData.append('timezone', this.eventContent.timezone);
            formData.append('venue', this.eventContent.venue);
            formData.append('country', this.eventContent.country);
            formData.append('state', this.eventContent.state);
            formData.append('paymentgateway', this.eventContent.paymentgateway);
            formData.append('city', this.eventContent.city);
            formData.append('description', this.eventContent.description);
            formData.append('category', this.eventContent.category);
            formData.append('organisation', this.eventContent.organizer);

            EventService.createEvent(formData).then(response => {
                    
                    this.message = "Event Created Successfully";
                    console.log(this.eventscontent);
                    this.loading = false;

            },
            error => {
                console.log(error);
                this.error = error.response.data.message;
                console.log(error.response.data);


                this.loading = false;
                 window.scrollTo(0,0)
            });
        },

            
        handleFileUpload(){
        this.file = this.$refs.file.files[0];
      }
     },
      mounted(){
        window.scrollTo(0,0)

        let externalScriptQuill = document.createElement('script')
        let externalScriptRich = document.createElement('script')

        externalScriptQuill.setAttribute('src', 'https://cdn.statically.io/gh/NathTimi/scripts/main/quill.min.js')
        externalScriptRich.setAttribute('src', 'https://cdn.statically.io/gh/NathTimi/scripts/main/rich-text.js')

        document.head.appendChild(externalScriptQuill)
        document.head.appendChild(externalScriptRich)
      }
    }
</script>